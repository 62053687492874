<template>
  <container-list-extend title="Visite" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="p-1 p-md-2 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <h6 class="m-0 ml-1" v-if="satisfaction.satisfaction_description">
          {{ satisfaction.satisfaction_description }}
        </h6>
        <h6 class="m-0 ml-1" v-else>Satisfaction n°{{ satisfaction.id }}</h6>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <div v-if="satisfaction.satisfaction_date">
            Le :
            {{ $moment(satisfaction.satisfaction_date).format("DD/MM/YYYY") }}
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form deleteBtn @click="updateSatisfaction" @delete="deleteSatisfaction" :loading="loading">
        <base-form-row row>
          <base-inputDatePicker
            v-model="satisfaction.satisfaction_date"
            inputText="Date"
            :errors="feedback.satisfaction_date"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="satisfaction.satisfaction_commentaire"
            text="Synthèse"
            :errors="feedback.satisfaction_commentaire"
          ></base-input-area>
        </base-form-row>

        <div class="w-100">
          <uploader
            v-if="satisfaction.id"
            id="document_affaire_satisfaction"
            url="/upload/document"
            title="Déposer vos fichiers"
            :file_system="'CRM'"
            :file_field="'satisfaction_' + satisfaction.id"
            :file_key="satisfaction.satisfaction_affaire_id"
          ></uploader>
        </div>

        <div class="alert mt-3" v-background-1>
          <small>
            <div>1 étoile : très insuffisant</div>
            <div>2 étoiles : insuffisant</div>
            <div>3 étoiles : satisfaisant</div>
            <div>4 étoiles : très satisfaisant</div>
          </small>
        </div>

        <base-form-row row class="align-items-center">
          <BaseInputStars v-model="satisfaction.satisfaction_note_commerce"></BaseInputStars>
          <div class="w-100">
            <div>COMMERCE</div>
            <div class="text-muted">
              <small>
                Communication, disponibilité, ponctualité, compréhension des besoins, suggestions techniques,
                réactivité, clarté, pertinence de l’offre technique et commerciale…
              </small>
            </div>
          </div>
        </base-form-row>
        <base-form-row row class="align-items-center mt-3">
          <BaseInputStars v-model="satisfaction.satisfaction_note_pilotage"></BaseInputStars>
          <div class="w-100">
            <div>PILOTAGE DE LA PRESTATION</div>
            <div class="text-muted">
              <small> Planification, reporting, réactivité, suivi et maitrise des délais… </small>
            </div>
          </div>
        </base-form-row>
        <base-form-row row class="align-items-center mt-3">
          <BaseInputStars v-model="satisfaction.satisfaction_note_technique"></BaseInputStars>
          <div class="w-100">
            <div>MAÎTRISE TECHNIQUE</div>
            <div class="text-muted">
              <small> Expertise technique, force de proposition, maîtrise des outils… </small>
            </div>
          </div>
        </base-form-row>
        <base-form-row row class="align-items-center mt-3">
          <BaseInputStars v-model="satisfaction.satisfaction_note_qsser"></BaseInputStars>
          <div class="w-100">
            <div>QSSER</div>
            <div class="text-muted">
              <small>
                Prise en compte des exigences qualité, santé, sécurité, environnement et radioprotection / comportement
                sur le terrain, niveau de compétence, attitude interrogative des collaborateurs…
              </small>
            </div>
          </div>
        </base-form-row>
        <base-form-row row class="align-items-center mt-3">
          <BaseInputStars v-model="satisfaction.satisfaction_note_prix"></BaseInputStars>
          <div class="w-100">
            <div>COMPÉTITIVITÉ</div>
            <div class="text-muted">
              <small>Rapport qualité / prix</small>
            </div>
          </div>
        </base-form-row>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInputStars from "@/components/bases/InputStars.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    BaseInputStars,
    Uploader,
  },
  data() {
    return {
      feedback: {},
      loading: false,
    };
  },
  props: {
    satisfaction: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
    lists: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
    affaires: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
  },
  computed: {
    ...mapGetters({ data: "clients/data" }),
  },
  methods: {
    ...mapActions({
      _updateSatisfaction: "affaires/updateSatisfaction",
      _deleteSatisfaction: "affaires/deleteSatisfaction",
    }),
    updateSatisfaction: function () {
      this.feedback = {};
      this.loading = true;
      console.log(this.satisfaction);
      this._updateSatisfaction(this.satisfaction)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteSatisfaction: function () {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteSatisfaction(this.satisfaction)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="css"></style>
